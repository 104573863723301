<template>


    <div>
        <v-container>
            <br>
            <v-card flat>
                <v-tabs v-model="tab" @change="initialize()" background-color="primary" dark>
                    <v-tab v-for="item in items" :key="item.tab">
                        {{ item.tab }}
                    </v-tab>
                </v-tabs>

                <br>
                <br>

                <div v-if="tab==0">

                    <v-row>



                        <v-col class="py-0" cols="12" sm="6" md="6">
                            <v-select v-model="serId" :label="$t('cats')" :items="options" outlined item-text="value"
                                item-value="id"></v-select>
                        </v-col>

                        <v-col class="py-0" cols="12" sm="6" md="6"></v-col>

                        <v-col class="py-0" cols="6" sm="12" md="6">
                            <v-text-field label="اختر الصورة" @click='pickImg' v-model='imageName'
                                prepend-icon='mdi-paperclip'>
                            </v-text-field>
                            <input type="file" style="display: none" ref="image" accept="image/*"
                                @change="onFilePicked">
                        </v-col>





                        <v-col class="py-0" cols="12" sm="4" md="4">

                            <v-btn color="primary" :loading="loadSave" @click="uploudeImgSer()">
                                رفع
                            </v-btn>
                        </v-col>

                    </v-row>

                </div>
            </v-card>
        </v-container>
    </div>
</template>


<script>
    import Swal from "sweetalert2";
    import axios from "axios";
    export default {
        data() {
            return {


                imageFile: '',
                optionsSer: [],
                imagesUrl: '',
                imageName: '',
                serId: '',
                imageUrl: '',
                file_name: null,
                tab: 0,
                loading: false,
                options: [],
                loadSave: false,
                items: [{
                    tab: 'رفع صور غلاف الصفحات'
                }, ],
            }
        },

        created() {
            this.initialize();
            //this.initializePic();
        },
        methods: {

            deleteOption(index, id) {
                // this.sumPay();

                Swal.fire({
                    title: this.$t('sure_process'),
                    text: "",
                    heightAuto: false,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                }).then(result => {
                    if (result.value) {
                        this.options.splice(index, 1);
                        axios.delete("options/delete/" + id, {
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                                }
                            })
                            .then(() => {
                                this.$swal.fire(this.$t('Successfully'), this.$t('done'), "success");
                                this.initialize();
                            })
                            .catch(() => {
                                this.$swal.fire(this.$t('not_successful'), this.$t('not_done'), "error");
                            });
                    }
                });




            },

            addOption() {
                this.options.push({
                    id: '',
                    option: 'services',
                    value: '',
                    active: 1,
                    description: '',
                    img_file: '',
                    langid: 1,
                    option_categories_id: 5,

                })



            },




            pickFile() {
                this.$refs.file.click()
            },
            onPickfile(e) {

                this.file = this.$refs.file.files[0];
                let file = e.target.files;
                this.file = file[0]
                this.file_name = file[0].name
            },


            pickFiles() {
                this.$refs.images.click()

            },
            onFilePicked(e) {
                const files = e.target.files
                if (files[0] !== undefined) {
                    this.imageName = files[0].name
                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }
                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {
                        this.imageUrl = fr.result
                        this.imageFile = files[0]
                    })
                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''
                }
            },



            uploudeImgSer() {

                if (this.imageFile == '') {
                    this.loadSave = false;
                    this.$swal.fire({
                        title: "يجب اختيار صوره",
                        text: "",
                        icon: "error",
                        confirmButtonText: "اغلاق",
                    });

                }

                if (this.imageFile != null) {
                    this.loadSave = true;
                    // var x = parseInt(this.tab) + 1;
                    let formData = new FormData();

                    formData.append('image', this.imageFile);

                  
                    axios.post('options/uploadImage/' + this.serId, formData, {
                                        headers: {
                                            Authorization: "Bearer " + this.$store.state.AdminInfo.token,
                                            'Content-Type': 'multipart/form-data',
                                        }
                                    })



                        .then(() => {
                            this.loadSave = false;
                            this.$swal.fire({
                                title: "تم تعديل ",
                                text: "",
                                icon: "success",
                                confirmButtonText: "اغلاق",
                            });
                            this.$refs.form.resetValidation();



                            this.initialize();
                            this.img_old = ''
                            this.imageName = ''
                            this.imageFile = ''
                            this.imageUrl = ''

                            this.$swal.fire({
                                title: "تم تعديل ",
                                text: "",
                                icon: "success",
                                confirmButtonText: "اغلاق",
                            });
                        })

                }

            },

            initialize() {

                this.loading = true;
                try {

                    // if (this.tab == 5) {
                    //     var x = 5;
                    // } 
                    // else  if (this.tab == 6) {
                    //      x = 6;
                    // }

                    // else {
                    //      x = parseInt(this.tab) + 1;
                    // }


                    axios.get('options/optionsByCat/6', {
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                            }
                        }).then(res => {
                            this.loading = false;
                            this.options = res.data.data

                        })
                        .catch(() => {
                            this.loading = false;
                        });
                } catch (e) {
                    e
                }

            },
            uploudeImg() {


                if (this.imageFile == '') {
                    this.loadSave = false;
                    this.$swal.fire({
                        title: "يجب اختيار صوره",
                        text: "",
                        icon: "error",
                        confirmButtonText: "اغلاق",
                    });

                }

                if (this.imageFile != null) {


                    let formData = new FormData();

                    formData.append('image', this.imageFile);




                    axios.post('options/uploadImage/' + this.options[0].id,

                            formData, {
                                headers: {
                                    Authorization: "Bearer " + this.$store.state.AdminInfo.token,
                                    'Content-Type': 'multipart/form-data',
                                }
                            }
                        )
                        .then(() => {

                            this.$refs.form.resetValidation();

                            this.loadSave = false;

                            this.initialize();
                            this.img_old = ''
                            this.imageName = ''
                            this.imageFile = ''
                            this.imageUrl = ''

                            this.$swal.fire({
                                title: "تم تعديل ",
                                text: "",
                                icon: "success",
                                confirmButtonText: "اغلاق",
                            });
                        })

                }
            },



            pickImg() {
                this.$refs.image.click()
            },


            save() {
                if (this.$refs.form.validate()) {


                    this.loadSave = true;

                    var data = {
                        data: this.options
                    };
                    var x = parseInt(this.tab) + 1;

                    this.axios
                        .put("options/update/" + x, data, {

                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: "Bearer " + this.$store.state.AdminInfo.token
                            },
                        })
                        .then(() => {

                            this.$refs.form.resetValidation();

                            this.loadSave = false;

                            this.initialize();


                            this.$swal.fire({
                                title: "تم تعديل ",
                                text: "",
                                icon: "success",
                                confirmButtonText: "اغلاق",
                            });
                        })
                        .catch(() => {
                            this.loadSave = false;

                            this.$swal.fire({
                                title: "تاكد من ملى المعلومات",
                                text: "",
                                icon: "error",
                                confirmButtonText: "اغلاق",
                            });
                        });





                }
            },
        }

    }
</script>